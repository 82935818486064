import instance from 'shared/helpers/axios';
import { catchError } from 'shared/helpers/catchError';
import { BASE_LIST_URL } from '../constants';

import { ICountriesListDTO, ICountriesListsDTO, ICreateListParams, TType } from './types';

export const getCountriesLists = async (type: TType) => {
  try {
    const { data } = await instance.get<ICountriesListsDTO>(`${BASE_LIST_URL}/${type}`);
    return data;
  } catch (error) {
    catchError(error);
  }
};

export const getCountriesList = async (ids: number[]) => {
  try {
    const { data } = await instance.post<ICountriesListDTO>(`${BASE_LIST_URL}`, {
      ids,
    });
    return data;
  } catch (error) {
    catchError(error);
  }
};

// id === 0 if new list

export const createCountriesList = async (params: ICreateListParams, type: TType) => {
  try {
    const { data } = await instance.post(`${BASE_LIST_URL}/${type}`, params);
    return data;
  } catch (error) {
    catchError(error);
  }
};

export const removeCountriesList = async (id: number) => {
  try {
    const { data } = await instance.delete(`${BASE_LIST_URL}`, { data: { id } });
    return data;
  } catch (error) {
    catchError(error);
  }
};
