import React, { FC } from 'react';

import { sidebarData } from 'shared/constants/sidebarData';
import { smsPath, hlrPath } from 'shared/constants/sideBarRoutes';
import Icon from 'shared/components/Icon';

import { IconTab, Wrapper, StyledLink, IconText } from './style';

const SidebarPanel: FC = () => {
  return (
    <Wrapper>
      {sidebarData.map(({ iconName, path, label }) => (
        <StyledLink
          key={path}
          to={path}
          activeStyle={{ color: '#1890ff' }}
          isActive={(match, location) => {
            if (smsPath.includes(location.pathname) && label === 'SMS') {
              return true;
            }
            return hlrPath.includes(location.pathname) && label === 'HLR';
          }}
        >
          <IconTab>
            <Icon name={iconName} />
          </IconTab>
          <IconText>{label}</IconText>
        </StyledLink>
      ))}
    </Wrapper>
  );
};

export default SidebarPanel;
