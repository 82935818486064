import React, { FC, useEffect, useState } from 'react';
import { Popover } from 'antd';
import { observer } from 'mobx-react-lite';

import fetchUser from 'shared/api/auth';
import userPopoverContent from './userPopoverContent';
import { Wrapper, UserName } from './style';

const User: FC = observer(() => {
  const inputRef = React.createRef<HTMLDivElement>();
  const [user, setUser] = useState<string>('');

  useEffect(() => {
    fetchUser().then(data => {
      if (data.data) setUser(data.data.login);
    });
  }, []);

  const simulateClick = () => {
    // @ts-ignore
    return inputRef.current.click();
  };

  return (
    <Wrapper onClick={simulateClick}>
      <Popover
        placement="bottomRight"
        content={userPopoverContent}
        overlayClassName="user__popover"
        trigger="click"
      >
        <div ref={inputRef}>
          <UserName>{user}</UserName>
        </div>
      </Popover>
    </Wrapper>
  );
});

export default User;
