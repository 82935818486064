export type TStats = {
  fact: number;
  plan: number;
};

export type TProvider = {
  id: number;
  name: string;
  stats: TStats;
};

export type TProviders = { [key in number]: TProvider };

export type TOperators = {
  [key in number]: { id: number; name: string; providers: TProviders };
};

export const getHlrOperators = (operators: TOperators) => {
  const operatorsArray = Object.values(operators);

  return operatorsArray.map(operator => {
    const providersArray = Object.values(operator.providers);

    const providerValues = (providersArray as TProvider[]).map((provider: TProvider) => {
      return {
        [`${provider.id}`]: [provider.stats.fact, provider.stats.plan],
      };
    });

    const result = providerValues.reduce((accumulator, currentValue) => {
      return Object.assign(accumulator, currentValue);
    });

    return {
      key: operator.id,
      network: operator.name,
      ...result,
    };
  });
};
