import { catchError } from 'shared/helpers/catchError';
import { processOperators } from 'shared/helpers/getSmsCell';
import { processSmsProviders } from 'shared/helpers/processSmsProviders';

import { TDataItem } from 'modules/Results/Channels/Sms/Results/ResultsTab/components/ResultsTable/components/TableLvl3/types';
import instance from 'shared/helpers/axios';
import {
  IGetDataLvl1Params,
  TMassBody,
  TParamsLvl3,
  ICountryDTO,
  IFetchCountriesDTO,
} from './types';

// const fetchSMPPData = async (params: IGetSMPPDataParams) => {
//   try {
//     const response = await instance.get(`/tests/smpps`, { params });
//
//     const { data } = response && response.data;
//     const { items } = data;
//
//     return { items };
//   } catch (error) {
//     catchError(error);
//   }
// };

const fetchDataLvl1 = async (params: IGetDataLvl1Params) => {
  try {
    const res = await instance.get(`/tests/`, { params });

    const { total } = res.data.data;
    const responseObject = res.data.data?.items;
    const responseArray = Object.values(responseObject);
    responseArray.reverse();

    const successProp = { successOnly: false };
    const withSuccessProp = responseArray.map((item: any) => {
      return { ...item, ...successProp };
    });

    return {
      items: withSuccessProp,
      total,
    };
  } catch (error) {
    catchError(error);
  }
};

const fetchDataLvl2 = async (testID: number, params: any) => {
  try {
    const res = await instance.get(`/tests/${testID}/operators`, { params });
    const { operators } = res.data.data.items;
    const { total } = res.data.data;

    return {
      columns: processSmsProviders(operators),
      data: processOperators(operators),
      total,
    };
  } catch (error) {
    catchError(error);
  }
};

const fetchDataLvl3 = async (testID: number, operatorID: number, params: TParamsLvl3) => {
  try {
    const response = await instance.get(
      `/tests/${testID}/operators/${operatorID}/messages`,
      { params },
    );

    const { items, total } = response.data.data;
    const data: TDataItem[] = Object.values(items);

    return {
      data,
      total,
    };
  } catch (error) {
    catchError(error);
  }
};

const fetchCountriesData = async () => {
  try {
    const response = await instance.get<IFetchCountriesDTO>(`/tests/countries`);
    return Object.values(response.data.data.items) as ICountryDTO[];
  } catch (error) {
    catchError(error);
  }
};

const sendMassTestSMS = async (body: TMassBody) => {
  try {
    const {
      data: { data },
    } = await instance.post(`/tests/multiple`, body);
    return data;
  } catch (error) {
    catchError(error);
  }
};

const sendSingleTestSMS = async (body: any) => {
  try {
    const {
      data: { data },
    } = await instance.post(`/tests/single`, body);
    return data;
  } catch (error) {
    catchError(error);
  }
};

const getGTSenders = async () => {
  try {
    const { data } = await instance.get(`/tests/gts`);
    return data;
  } catch (error) {
    catchError(error);
  }
};

const exportDataSmsResults = async (id: number) => {
  try {
    const { data } = await instance.get(`/tests/${id}/export`);
    return data;
  } catch (error) {
    catchError(error);
  }
};

export default {
  fetchDataLvl1,
  fetchDataLvl2,
  fetchDataLvl3,
  fetchCountriesData,
  sendMassTestSMS,
  sendSingleTestSMS,
  getGTSenders,
  exportDataSmsResults,
};
