import React, { FC } from 'react';

import { ReactComponent as LogoImage } from 'shared/assests/img/navbar/logo.svg';
import { Wrapper, Text } from './style';

const Logo: FC = () => {
  return (
    <Wrapper>
      <LogoImage />
      <Text>MVNO</Text>
    </Wrapper>
  );
};

export default Logo;
