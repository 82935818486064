import { Component } from 'react';

import ErrorIndicator from '../ErrorIndicator';

export class ErrorBoundary extends Component {
  state = {
    errorInfo: { componentStack: '' },
    hasError: false,
  };

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      hasError: true,
      errorInfo,
    });
  }

  render() {
    const {
      hasError,
      errorInfo: { componentStack },
    } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorIndicator errorInfo={componentStack.toString()} />;
    }

    return children;
  }
}
