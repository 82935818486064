import { message as antdMessage } from 'antd';

import { withAttrs } from './wrappers';

export const getErrorText = (error: any) => {
  const { data } = error || {};
  const { errors } = data || {};
  return errors
    ? typeof errors === 'string'
      ? errors
      : (Object.values(errors)[0] as any)
    : null;
};

export const catchError = (error: any) => {
  const errorText = getErrorText(error) || 'Something went wrong';
  antdMessage.error(
    withAttrs(errorText, {
      'data-cy': 'popup-error-message',
    }),
  );
  throw error;
};

export const isError = (error: any) => {
  const { data } = error || {};
  const { errors } = data || {};
  return Boolean(errors);
};
