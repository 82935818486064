import { flow, types as t } from 'mobx-state-tree';

import hlrApi from 'shared/api/hlr';
import { TParams, TParamsLvl3 } from 'shared/api/hlr/types';
import { OptionProviderM } from '../../sms/smsResults';

export const HlrItemLvl1 = t.model('HlrItemLvl1', {
  a: t.boolean,
  created: t.maybeNull(t.string),
  id: t.number,
  // insertion_mode: t.string,
  n: t.boolean,
  p: t.boolean,
  provider: t.maybeNull(t.string),
  s: t.boolean,
  successOnly: t.boolean,
  pending: t.boolean,
  count: t.optional(t.number, 0),
  progress: t.number,
  options: OptionProviderM,
  operators_count: t.number,
  gt_count: t.number,
  user_count: t.number,
  events_sent: t.number,
  events_delivered: t.union(t.number, t.string),
  user_name: t.maybeNull(t.string),
  success_operators: t.union(t.number, t.string),
});

export const HlrResultsModel = t
  .model('HlrResultsModel', {
    refreshSecondLevelData: t.boolean,
    refreshThirdLevelData: t.boolean,
    dataLvl1: t.array(HlrItemLvl1),
    totalLvl1: t.number,
    areAllMessageDetailsVisible: t.boolean,
    smscValue: t.string,
  })
  .actions(self => ({
    setSuccessStatus(i: number, status: boolean) {
      self.dataLvl1[i].successOnly = status;
    },
    getDataLvl1: flow(function* getDataLvl1(params: TParams) {
      const res = yield hlrApi.fetchDataLvl1(params);

      self.dataLvl1 = res.items;
      self.totalLvl1 = res.total;
    }),
    setRefreshSecondLevelData(status: boolean) {
      self.refreshSecondLevelData = status;
    },
    getResultsLvl2: flow(function* getDataLvl2(testID, params) {
      return yield hlrApi.fetchDataLvl2(testID, params);
    }),
    getResultsLvl3: flow(function* (
      testID: number,
      operatorID: number,
      params: TParamsLvl3,
    ) {
      return yield hlrApi.fetchDataLvl3(testID, operatorID, params);
    }),
    setRefreshThirdLevelData(status: boolean) {
      self.refreshThirdLevelData = status;
    },
    setAllMessageDetailsVisibility(status: boolean) {
      self.areAllMessageDetailsVisible = status;
    },
    exportResults: flow(function* (id) {
      return yield hlrApi.exportDataHlrResults(id);
    }),
    setSmscValue(value: string) {
      self.smscValue = value;
    },
  }));
