import { FC } from 'react';
import { Collapse } from 'antd';

import {
  ErrorWrapper,
  Title,
  SubTitle,
  Text,
  Link,
  StyledCollapse,
  Astronaut,
  Info,
} from './style';
import { TErrorIndicatorProps } from './types';

const { Panel } = Collapse;

const ErrorIndicator: FC<TErrorIndicatorProps> = ({ errorInfo }) => {
  const reloadHandler = () => window.location.reload();

  return (
    <ErrorWrapper>
      <Text>
        <Title>Oh no</Title>
        <SubTitle>Something has gone terribly wrong</SubTitle>
        <hr />
        <span>Try again later please</span>
        <Info>
          There was an error in loading this page.{' '}
          <Link type="link" onClick={reloadHandler}>
            Reload this page
          </Link>
        </Info>
        <StyledCollapse accordion>
          <Panel header="Click for error details" key="1">
            <p>{errorInfo && errorInfo}</p>
          </Panel>
        </StyledCollapse>
      </Text>
      <Astronaut>
        <img
          src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-space-astronaut-cartoon-by-vexels.png"
          alt="error icon"
          className="src"
        />
      </Astronaut>
    </ErrorWrapper>
  );
};

export default ErrorIndicator;
