import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 25px 0 25px;
  cursor: pointer;
`;
export const UserName = styled.div`
  padding: 0;
  display: flex;
  line-height: 20px;
`;
