/* eslint-disable */
import { FC, SVGProps } from 'react';
import { ReactSVG } from 'react-svg';

import { TIconProps } from './types';

const Icon: FC<TIconProps & SVGProps<SVGSVGElement>> = ({ name }) => {
  let path = '<svg></svg>';

  try {
    path = require(`shared/assests/icons/${name}.svg`).default;

    console.log(path);
  } catch (err) {
    console.warn(err);
  }

  return (
    <ReactSVG
      style={{ pointerEvents: 'none' }}
      title=""
      src={path}
      wrapper="span"
      data-icon={name}
    />
  );
};

export default Icon;
