export const smsPath: string[] = [
  '/checking/test/sms/mass',
  '/checking/result/sms',
  '/checking/test/sms/single',
];

export const hlrPath: string[] = ['/checking/result/hlr', '/checking/test/hlr'];
// export const smsBetaPath: string[] = [
//   '/checking/result/smsBeta',
//   '/checking/test/smsBeta/mass',
//   '/checking/test/smsBeta/single',
// ];
