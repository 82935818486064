import React, { useEffect } from 'react';
import { parse } from 'query-string';
import Router from 'Router';

import LayoutPage from 'shared/components/LayoutPage';
import { DEFAULT_FETCH_PER_PAGE } from 'shared/constants/tableParams';
import ErrorBoundary from './shared/components/ErrorBoundary';
import { useSmsResultsStore } from './store/models/sms/Hooks';
import { useHlrResultsStore } from './store/models/hlr/Hooks';

const App = () => {
  const { id } = parse(window.location.search);
  const { smsResults } = useSmsResultsStore();
  const { hlrResults } = useHlrResultsStore();

  useEffect(() => {
    if (id) {
      const params = {
        page: 1,
        per: DEFAULT_FETCH_PER_PAGE,
        ext: id,
        type: 'sms',
      };

      smsResults.getDataLvl1(params);
      hlrResults.getDataLvl1(params);
    }
  }, [id]);

  return (
    <ErrorBoundary>
      <Router LayoutPage={LayoutPage} />
    </ErrorBoundary>
  );
};

export default App;
