import { TSidebar } from 'shared/components/Sidebar/types';

export const sidebarData: TSidebar<string>[] = [
  {
    iconName: 'sms',
    path: '/checking/result/sms',
    label: 'SMS',
  },
  {
    iconName: 'hlr',
    path: '/checking/result/hlr',
    label: 'HLR',
  },
];
