import styled from 'styled-components';

const Cell = styled.div`
  width: calc(100% - 1px);
  color: white;
  text-align: center;
  padding: 3px 0;
`;

export const RedCell = styled(Cell)`
  background-color: #eb5757;
`;
export const GreenCell = styled(Cell)`
  background-color: #6fcf97;
`;
export const YellowCell = styled(Cell)`
  background-color: #f2c94c;
`;
export const GrayCell = styled(Cell)`
  background-color: #e0e0e0;
`;
