import { manageCell } from './getSmsCell';

export const processSmsProviders = (operators: any[]) => {
  // const providersArray: TProviderData[] = Object.values(providers);

  const renderOperator = operators.length && operators[0];
  return [
    {
      title: 'ALL providers',
      dataIndex: renderOperator?.id,
      className: 'column-parent',
      width: 165,
      children: [
        {
          title: 'FACT / PLAN',
          dataIndex: 'stats',
          align: 'center',
          width: 30,
          className: 'column-minified',
          render: (text: number[]) => {
            return manageCell(text);
          },
        },
        // {
        //   title: 'N',
        //   dataIndex: `n${provider.id}`,
        //   align: 'center',
        //   width: 30,
        //   className: 'column-minified',
        //   render: (text: number[]) => manageCell(text),
        // },
        // {
        //   title: 'A',
        //   dataIndex: `a${provider.id}`,
        //   align: 'center',
        //   width: 30,
        //   className: 'column-minified',
        //   render: (text: number[]) => manageCell(text),
        // },
        // {
        //   title: 'S',
        //   dataIndex: `s${provider.id}`,
        //   align: 'center',
        //   width: 30,
        //   className: 'column-minified',
        //   render: (text: number[]) => manageCell(text),
        // },
        // {
        //   title: 'GT',
        //   dataIndex: `gt${provider.id}`,
        //   align: 'center',
        //   width: 30,
        //   className: 'column-minified',
        //   render: (text: number[]) => manageCell(text),
        // },
        // {
        //   title: 'GT+',
        //   dataIndex: `gtp${provider.id}`,
        //   align: 'left',
        //   width: 30,
        //   className: 'column-minified',
        //   render: (text: number[]) => manageCell(text, 'last'),
        // },
      ],
    },
  ];
};
