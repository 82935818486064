import styled, { css } from 'styled-components';
import { Button, Collapse } from 'antd';

const StyledBody = css`
  margin: 0;
  padding: 0;
  height: 100vh;
  background-image: linear-gradient(to top, #2e1753, #1f1746, #131537, #0d1028, #050819);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ErrorWrapper = styled.div`
  ${StyledBody}
`;

export const Title = styled.div`
  font-size: 50px;
  font-weight: 500;
  color: #ffffff;
`;

export const Astronaut = styled.div`
  img {
    width: 100px;
    position: absolute;
    top: 55%;
    animation: astronautFly 6s infinite linear;
  }
  @keyframes astronautFly {
    0% {
      left: -100px;
    }
    25% {
      top: 50%;
      transform: rotate(30deg);
    }
    50% {
      transform: rotate(45deg);
      top: 55%;
    }
    75% {
      top: 60%;
      transform: rotate(30deg);
    }
    100% {
      left: 110%;
      transform: rotate(45deg);
    }
  }
`;

export const SubTitle = styled.span`
  font-size: 24px;
  color: #ffffff;
`;

export const Star = styled.span`
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  right: 0;
  animation: starTwinkle 3s infinite linear;

  @keyframes starTwinkle {
    0% {
      background: rgba(255, 255, 255, 0.4);
    }
    25% {
      background: rgba(255, 255, 255, 0.8);
    }
    50% {
      background: rgba(255, 255, 255, 1);
    }
    75% {
      background: rgba(255, 255, 255, 0.8);
    }
    100% {
      background: rgba(255, 255, 255, 0.4);
    }
  }
`;

export const Link = styled(Button)`
  margin-top: 10px;
  cursor: pointer;
  color: #0077ff;
`;

export const Text = styled.div`
  position: absolute;
  top: 10%;
  color: #fff;
  text-align: center;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledCollapse = styled(Collapse)`
  max-width: 360px;
`;
