import React from 'react';
import { RedCell, GreenCell, YellowCell, GrayCell } from 'shared/components/style';

export type TStats = {
  // a: { fact: number; plan: number };
  // gt: { fact: number; plan: number };
  // gtp: { fact: number; plan: number };
  // n: { fact: number; plan: number };
  // s: { fact: number; plan: number };
};

export type TProvider = {
  id: number;
  name: string;
  stats: { fact: number; plan: number };
};

export const manageCell = (text: number[], isLastColumn?: string) => {
  let style;
  if (isLastColumn) {
    style = {
      borderRight: '5px solid #FAFAFA',
      marginRight: '25px',
    };
  }
  const plan = text[1];
  const fact = text[0];

  const delta = plan - fact;
  if (delta === 0 && fact > 0) {
    return <GreenCell style={style}>{`${fact}/${plan}`}</GreenCell>;
  }
  if (fact === 0 && plan !== 0) {
    return <RedCell style={style}>{`${fact}/${plan}`}</RedCell>;
  }
  if (delta >= 1) {
    return <YellowCell style={style}>{`${fact}/${plan}`}</YellowCell>;
  }
  if (fact === 0 && plan === 0) {
    return <GrayCell style={style}>0/0</GrayCell>;
  }
};

export const processOperators = (operators: any[]) => {
  const operatorsArray = Object.values(operators);

  return operatorsArray.map(operator => {
    const providersArray = Object.values(operator.providers);

    const providerValues = (providersArray as TProvider[]).map((provider: TProvider) => {
      return {
        stats: [provider?.stats.fact, provider?.stats.plan],
        // [`n${provider.id}`]: [provider.stats.n.fact, provider.stats.n.plan],
        //
        // [`a${provider.id}`]: [provider.stats.a.fact, provider.stats.a.plan],
        //
        // [`s${provider.id}`]: [provider.stats.s.fact, provider.stats.s.plan],
        //
        // [`gt${provider.id}`]: [provider.stats.gt.fact, provider.stats.gt.plan],
        //
        // [`gtp${provider.id}`]: [provider.stats.gtp.fact, provider.stats.gtp.plan],
      };
    });

    const result = providerValues.reduce((accumulator, currentValue) => {
      return Object.assign(accumulator, currentValue);
    });

    return {
      key: operator.id,
      network: operator.name,
      ...result,
    };
  });
};
