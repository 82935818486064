import { Instance } from 'mobx-state-tree';

import { useStore } from 'index';
import { SmsSMPPDataModel, SmsResult } from './smsData';
import { SmsItemLvl1 } from './smsResults';

export interface SmsResultType extends Instance<typeof SmsResult> {}
export interface SmsDataLvl1Type extends Instance<typeof SmsItemLvl1> {}
export interface SmsSMPPDataType extends Instance<typeof SmsSMPPDataModel> {}

export const useSmsResultsStore = () => {
  const {
    sms: { results },
  } = useStore();
  const { areAllMessageDetailsVisible } = results;

  return {
    smsResults: results,
    totalLvl1: results.totalLvl1,
    areAllMessageDetailsVisible,
  };
};

export const useSmsDataStore = () => {
  const {
    sms: { data },
  } = useStore();
  const { SMPPData, smsSingleTestResults, smsCountriesLists } = data;

  return {
    smsData: data,
    SMPPData,
    smsSingleTestResults,
    countriesLists: smsCountriesLists,
  };
};
