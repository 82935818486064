import { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { Container, Main, Btn, Text } from './style';
import { NotFoundProps } from './types';

const NotFound: FC<NotFoundProps> = ({ text }) => {
  return (
    <Container>
      <Main>
        <h1 style={{ fontSize: 120, textAlign: 'center' }}>404</h1>
        <Btn>
          <NavLink to="/">&#8617; Go home</NavLink>
        </Btn>
        <Text>{text}</Text>
      </Main>
    </Container>
  );
};

export default NotFound;
