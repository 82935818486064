import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  //justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(15, 76, 129, 1) 0%,
    rgba(9, 9, 121, 1) 47%,
    rgba(0, 159, 255, 1) 100%
  );
  color: #ffffff;
  min-height: 54px;
  font-size: 14px;
  line-height: 32px;
  box-shadow: var(--box-shadow-level-1);
  z-index: var(--z-index-30);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ColumnLeft = styled.div`
  display: flex;
`;

export const SRightSection = styled.div`
  display: flex;
  width: 94%;
  justify-content: space-between;
  align-items: center;
`;

export const SGitInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  padding-left: 25px;
`;
