import axios from 'axios';

const { REACT_APP_BASEURL: BASE_URL, REACT_APP_FETCH_TIMEOUT: FETCH_TIMEOUT = 0 } =
  process.env;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: +FETCH_TIMEOUT,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data) return Promise.reject(error.response);
  },
);

export default instance;
