import { lazy } from 'react';

import TestingView from 'views/TestingView';
import { withPath } from 'Router/helpers/withPath';
import { CheckingParams } from 'Router/constants/params';
import { IRoutesType } from 'Router/types';
import NotFound from 'views/NotFound';

const Results = lazy(() => import('modules/Results'));
const Test = lazy(() => import('modules/Test'));

export const testingRoutes: IRoutesType = {
  path: withPath([CheckingParams.view]),
  component: TestingView,
  modules: [
    {
      path: withPath([
        CheckingParams.view,
        CheckingParams.moduleResult,
        CheckingParams.channel,
      ]),
      exact: true,
      component: Results,
    },
    {
      path: withPath([
        CheckingParams.view,
        CheckingParams.moduleTest,
        CheckingParams.channelHlr,
      ]),
      exact: true,
      component: Test,
    },
    {
      path: withPath([
        CheckingParams.view,
        CheckingParams.moduleTest,
        CheckingParams.channelSms,
        CheckingParams.mode,
        '?',
      ]),
      exact: true,
      component: Test,
    },
    {
      path: withPath([CheckingParams.view, '/']),
      exact: true,
      component: Results,
    },
    {
      path: withPath([CheckingParams.view, '/*']),
      component: () => <NotFound text="Such a page does not exist" />,
    },
  ],
};
