import React, { FC } from 'react';
import preval from 'preval.macro';
import GitInfo from 'react-git-info/macro';
import { DesktopOutlined } from '@ant-design/icons';

import moment from 'moment';
import Logo from './components/Logo';
import User from './components/User';
import { Wrapper, ColumnLeft, SRightSection, SGitInfo } from './style';

const Navbar: FC = () => {
  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const buildDate = lastUpdateMoment.format('DD.MM.YYYY HH:mm');
  const gitInfo = GitInfo();
  const commitDate = moment(gitInfo.commit.date).format('DD.MM.YYYY HH:mm');

  return (
    <Wrapper>
      <ColumnLeft>
        <Logo />
      </ColumnLeft>
      <SRightSection>
        <SGitInfo>
          <DesktopOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
          FRONT: Branch: {gitInfo.branch}. Commit date: {commitDate}. Build date{' '}
          {buildDate}
        </SGitInfo>
        <User />
      </SRightSection>
    </Wrapper>
  );
};

export default Navbar;
