import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 0 10px 0;
  border-right: 1px solid #345ea3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 112px;
`;

export const Text = styled.div`
  align-self: flex-end;
  margin: 4px 13px 0 0;
  font-size: 8px;
  line-height: 8px;
  color: #fff;
`;
