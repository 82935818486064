import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const Main = styled.div`
  max-width: 1400px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Btn = styled.p`
  background-color: #535c6f;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`;

export const Text = styled.span`
  font-size: 16px;
  color: firebrick;
`;
