import { FC } from 'react';
import Loader from 'react-loader-spinner';

import { LoaderWrapper } from './style';
import { TCustomLoader } from './types';

const CustomLoader: FC<TCustomLoader> = ({ style }) => {
  return (
    <LoaderWrapper style={style}>
      <Loader type="Watch" color="#01ACF9" height={50} width={50} />
    </LoaderWrapper>
  );
};

export default CustomLoader;
