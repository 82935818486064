interface URLParams {
  protocol: string;
  domain: string;
  path: string;
  queryParams: { [key: string]: string };
}

function getParametersFromUrl(): URLParams {
  const urlObj = new URL(window.location.href);

  const queryParams: { [key: string]: string } = {};
  urlObj.searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return {
    protocol: urlObj.protocol,
    domain: urlObj.hostname,
    path: urlObj.pathname,
    queryParams,
  } as URLParams;
}

export default getParametersFromUrl;
