import { types as t, flow, destroy, cast } from 'mobx-state-tree';
import { isArray } from 'lodash';

import hlrApi from 'shared/api/hlr';
import {
  createCountriesList,
  getCountriesList,
  getCountriesLists,
  removeCountriesList,
} from 'shared/api/countries';
import { ICreateListParams, TCreateParams } from 'shared/api/countries/types';
import { IGetGtIds } from 'shared/api/shared/types';
import { fetchGtIds } from 'shared/api/shared';
import { HlrResultType } from '../Hooks';

export const HlrResult = t.model('HlrResult', {
  date: t.number,
  id: t.number,
});

export const CountriesLists = t.model('CountriesLists', {
  label: t.string,
  value: t.number,
});

export const HlrSMPPDataModel = t.model('HlrSMPPDataModel', {
  items: t.union(t.map(t.string), t.array(t.string)),
  items_hlr: t.union(t.map(t.string), t.array(t.string)),
});

const HlrSMPPModel = t.model('HlrSMPPModel', {
  SMPPData: HlrSMPPDataModel,
});

const HlrTestResultsModel = t.model('HlrTestResultsModel', {
  hlrTestResults: t.optional(t.array(HlrResult), []),
});

const HlrCountriesListsModel = t.model('HlrCountriesListsModel', {
  hlrCountriesLists: t.optional(t.array(CountriesLists), []),
});

export const HlrDataModel = t
  .compose(HlrSMPPModel, HlrTestResultsModel, HlrCountriesListsModel)
  .actions(self => ({
    fetchSMPPData: flow(function* fetchSMPPData(params: IGetGtIds) {
      const items = yield fetchGtIds(params);
      self.SMPPData.items = items;
      // self.SMPPData.items_hlr = items_hlr;
    }),
    submitTestHLR: flow(function* submitTestHLR(data) {
      const { items } = yield hlrApi.sendTestHLR(data);
      return items;
    }),
    addResults(newResults: HlrResultType) {
      self.hlrTestResults.push(newResults);
    },
    removeResult(resultToRemove: number) {
      const result = self.hlrTestResults.find(item => item.id === resultToRemove);
      destroy(result as HlrResultType);
    },
    clearAllResults() {
      self.hlrTestResults = cast([]);
    },
    createCountriesList: flow(function* (params: TCreateParams) {
      return yield createCountriesList({ id: 0, ...params }, 'hlr');
    }),
    updateCountriesList: flow(function* (params: ICreateListParams) {
      return yield createCountriesList(params, 'hlr');
    }),
    fetchCountriesLists: flow(function* () {
      const { data } = yield getCountriesLists('hlr');
      self.hlrCountriesLists = data.items;
    }),
    fetchCountriesList: flow(function* (id: number | number[]) {
      const requestId = isArray(id) ? id : [id];
      return yield getCountriesList(requestId);
    }),
    removeCountriesList: flow(function* (id: number) {
      return yield removeCountriesList(id);
    }),
  }));
