import { BarChartOutlined, AuditOutlined, ExportOutlined } from '@ant-design/icons';

export const tabs = {
  sms: [
    {
      title: 'SMS Single Test',
      url: '/checking/test/sms/single',
      Icon: ExportOutlined,
    },
    {
      title: 'SMS Mass Test',
      url: '/checking/test/sms/mass',
      Icon: BarChartOutlined,
    },
    {
      type: 'link',
      title: 'SMS Results',
      url: '/checking/result/sms',
      Icon: AuditOutlined,
    },
  ],
  hlr: [
    {
      title: 'HLR Test',
      url: '/checking/test/hlr',
      Icon: BarChartOutlined,
    },
    {
      type: 'link',
      title: 'HLR Results',
      url: '/checking/result/hlr',
      Icon: AuditOutlined,
    },
  ],
  // smsBeta: [
  //   {
  //     title: 'SMS Beta Single Test',
  //     url: '/checking/test/smsBeta/single',
  //     Icon: BarChartOutlined,
  //   },
  //   {
  //     title: 'SMS Beta Mass Test',
  //     url: '/checking/test/smsBeta/mass',
  //     Icon: BarChartOutlined,
  //   },
  //   {
  //     type: 'link',
  //     title: 'SMS Beta Test Results',
  //     url: '/checking/result/smsBeta',
  //     Icon: AuditOutlined,
  //   },
  // ],
};
