import instance from 'shared/helpers/axios';

import { catchError } from 'shared/helpers/catchError';

import { TDataItem } from 'modules/Results/Channels/Hlr/Results/ResultsTab/components/ResultsTable/components/TableLvl3/types';

import { getHlrColumnsByProviders } from 'shared/helpers/getHlrColumnsByProviders';
import { getHlrOperators } from 'shared/helpers/getHlrOperators';
import {
  TBody,
  TOperators,
  TParams,
  TParamsLvl3,
  TProvidersData,
  IFetchCountriesDTO,
  ICountryDTO,
} from './types';

const fetchDataLvl1 = async (params: TParams) => {
  try {
    const res = await instance.get(`/tests/`, {
      params,
    });
    if (res) {
      const { total } = res?.data.data;
      const responseObject = res.data.data?.items;
      const responseArray = Object.values(responseObject);
      responseArray.reverse();

      const successProp = { successOnly: false };
      const withSuccessProp = responseArray.map((item: any) => {
        return { ...item, ...successProp };
      });

      return {
        items: withSuccessProp,
        total,
      };
    }
    // Mock Data
    return {
      items: [],
      total: 10,
    };
  } catch (error) {
    catchError(error);
  }
};

const fetchDataLvl2 = async (testID: number, params: any) => {
  try {
    const res = await instance.get(`/tests/${testID}/operators`, {
      params,
    });
    if (res) {
      const {
        operators,
        providers,
      }: { operators: TOperators; providers: TProvidersData } = res.data.data.items;
      const { total } = res.data.data;

      return {
        columns: getHlrColumnsByProviders(providers),
        data: getHlrOperators(operators),
        total,
      };
    }
    // Mock data
    return { columns: [], data: [], total: 0 };
  } catch (error) {
    catchError(error);
  }
};

const fetchDataLvl3 = async (testID: number, operatorID: number, params: TParamsLvl3) => {
  try {
    const res = await instance.get(`/tests/${testID}/operators/${operatorID}/messages`, {
      params,
    });
    const { items, total } = res.data.data;
    const data: TDataItem[] = Object.values(items);

    return {
      data,
      total,
    };
  } catch (error) {
    catchError(error);
  }
};

const fetchCountriesData = async () => {
  try {
    const response = await instance.get<IFetchCountriesDTO>(`/tests/countries`);
    return Object.values(response?.data.data.items) as ICountryDTO[];
  } catch (error) {
    catchError(error);
  }
};

const sendTestHLR = async (body: TBody) => {
  try {
    const {
      data: { data },
    } = await instance.post(`/tests/hlr`, body);
    return data;
  } catch (error) {
    catchError(error);
  }
};

const exportDataHlrResults = async (id: number) => {
  try {
    const { data } = await instance.get(`/tests/${id}/export`);
    return data;
  } catch (error) {
    catchError(error);
  }
};

export default {
  // fetchSMPPHlrData,
  fetchDataLvl1,
  fetchDataLvl2,
  fetchDataLvl3,
  fetchCountriesData,
  sendTestHLR,
  exportDataHlrResults,
};
