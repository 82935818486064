import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { TabContainer, Tab, Title } from './style';
import { TNavigationTabs } from './types';

const NavigationTabs: FC<TNavigationTabs> = ({ items }) => {
  const location = useLocation();

  return (
    <TabContainer>
      {items.map(({ url, title, Icon }) => {
        const isActive = location.pathname === url;

        return (
          <NavLink key={url} to={url}>
            <Tab isActive={isActive}>
              <Icon />
              <Title>{title}</Title>
            </Tab>
          </NavLink>
        );
      })}
    </TabContainer>
  );
};

export default NavigationTabs;
