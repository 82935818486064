import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  display: flex;
  background-color: #eeeeee;
  height: 100%;
  width: 100%;
  padding-bottom: 150px;
  min-height: 100vh;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
`;
