/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, createContext } from 'react';
import ReactDOM from 'react-dom';
import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { mst } from 'reactotron-mst';
import { message } from 'antd';
import axios from 'axios';

import { rootStore } from './store/rootStore';

import './shared/assests/index.css';
import './shared/assests/fonts.css';
import 'antd/dist/antd.css';
import App from './App';

axios.defaults.withCredentials = true;
message.config({
  top: 78,
});

const RootStoreContext = createContext(rootStore);

if (process.env.NODE_ENV !== 'production') {
  Reactotron.configure().use(mst()).use(trackGlobalErrors({})).connect();
  // @ts-ignore
  Reactotron.trackMstNode(rootStore);
}

export const useStore = () => useContext(RootStoreContext);
ReactDOM.render(<App />, document.getElementById('root'));
