import instance from 'shared/helpers/axios';
import { BASE_URL } from '../constants';
import { catchError } from '../../helpers/catchError';

const fetchUser = async () => {
  try {
    const response = await instance.get(`${BASE_URL}/auth/ping`);

    return response.data;
  } catch (error) {
    catchError(error);
  }
};

export default fetchUser;
