import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--font-color-table);
  font-size: 12px;
  line-height: 32px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-content: flex-start;
  align-content: center;
  height: 100%;
  width: 112px;
  background-color: #f5f5f5;
  box-shadow: var(--box-shadow-level-1);
  svg {
    margin-left: 4px;
  }
`;

export const StyledLink = styled(NavLink)<NavLinkProps>`
  display: flex;
  width: 112px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px 0 10px 0;
  border-bottom: 1px solid var(--border-color-primary);
  cursor: pointer;
  color: var(--font-color-table);
  & a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #535c6f;
  }
  :hover {
    color: var(--font-color-table);
  }
  @media (max-height: 720px) {
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const IconTab = styled.div`
  width: 30px;
  height: 30px;
`;

export const IconText = styled.span`
  line-height: 18px;
  margin-top: 3px;
  text-align: center;
`;
