export const MIN_PAGE = 1;
export const PER_PAGE = 5;

export const SUCCESS_TIME_FROM_DEFAULT_HLR = 1;
export const SUCCESS_TIME_TILL_DEFAULT_HLR = 20;

export const SUCCESS_TIME_FROM_DEFAULT_SMS = 3;
export const SUCCESS_TIME_TILL_DEFAULT_SMS = 30;

export const TOTAL_DEFAULT = 0;

export const DEFAULT_FETCH_PAGE = 1;
export const DEFAULT_FETCH_PER_PAGE = 30;
export const TABLE_RESULTS_FETCH_PER_PAGE = 5;

export const PAGE_SIZE_OPTIONS = ['10', '30', '50'];
export const RESULTS_PAGE_SIZE_OPTIONS = ['5', '10', '20'];
export const DEFAULT_PAGE_SIZE = 5;
