import { TProvidersData, TProviderData } from 'shared/api/hlr/types';
import { manageHlrCell } from './manageHlrCell';

export const getHlrColumnsByProviders = (providers: TProvidersData) => {
  const providersArray: TProviderData[] = Object.values(providers);

  const childrenColumns = providersArray.map(provider => {
    return {
      title: `${provider.gt}`,
      dataIndex: `${provider.id}`,
      align: 'center',
      width: 30,
      className: 'column-minified',
      render: (text: number[]) => {
        return manageHlrCell(text, 'last');
      },
    };
  });

  return [
    {
      title: 'GT-IDs:',
      dataIndex: 'gt_ids',
      align: 'center',
      className: 'column-parent',
      width: 165,
      children: childrenColumns,
    },
  ];
};
