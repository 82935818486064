import React from 'react';
import { GrayCell, GreenCell, RedCell, YellowCell } from '../components/style';

export const manageHlrCell = (text: number[], isLastColumn?: string) => {
  const fact = text[0];
  const plan = text[1];

  let style;
  if (isLastColumn) {
    style = {
      borderRight: '5px solid #FAFAFA',
      marginRight: '25px',
    };
  }

  const delta = plan - fact;

  if (delta === 0 && fact > 0) {
    return <GreenCell style={style}>{`${fact}/${plan}`}</GreenCell>;
  }
  if (fact === 0 && plan !== 0) {
    return <RedCell style={style}>{`${fact}/${plan}`}</RedCell>;
  }
  if (delta >= 1) {
    return <YellowCell style={style}>{`${fact}/${plan}`}</YellowCell>;
  }
  if (fact === 0 && plan === 0) {
    return <GrayCell style={style}>0/0</GrayCell>;
  }
};
