import { Instance } from 'mobx-state-tree';

import { useStore } from 'index';
import { HlrSMPPDataModel, HlrResult } from './hlrData';
import { HlrItemLvl1 } from './hlrResults';

export interface HlrResultType extends Instance<typeof HlrResult> {}
export interface HlrDataLvl1Type extends Instance<typeof HlrItemLvl1> {}
export interface HlrSMPPDataType extends Instance<typeof HlrSMPPDataModel> {}

export const useHlrResultsStore = () => {
  const {
    hlr: { results },
  } = useStore();
  const { areAllMessageDetailsVisible } = results;

  return {
    hlrResults: results,
    totalLvl1: results.totalLvl1,
    areAllMessageDetailsVisible,
  };
};

export const useHlrDataStore = () => {
  const {
    hlr: { data },
  } = useStore();
  const { SMPPData, hlrTestResults, hlrCountriesLists } = data;

  return {
    hlrData: data,
    SMPPData,
    hlrTestResults,
    countriesLists: hlrCountriesLists,
  };
};
