import instance from 'shared/helpers/axios';
import { catchError } from 'shared/helpers/catchError';
import { IFetchGtIdsDTO, IGetGtIds } from './types';
import { TESTS_URL, GT_IDS_URL } from './constants';

export const fetchGtIds = async (params: IGetGtIds) => {
  try {
    const response = await instance.get<IFetchGtIdsDTO>(`${TESTS_URL}${GT_IDS_URL}`, {
      params,
    });

    const { data } = response && response.data;
    const { items } = data;

    return items;
  } catch (error) {
    catchError(error);
  }
};
