import { SMSC_TYPE_FIRST } from 'shared/constants/smscType';

export const initialSmsStore = {
  data: {
    SMPPData: {
      items: {},
    },
    smsSingleTestResults: [],
  },
  results: {
    refreshSecondLevelData: false,
    refreshThirdLevelData: true,
    dataLvl1: [],
    totalLvl1: 0,
    areAllMessageDetailsVisible: false,
    smscValue: SMSC_TYPE_FIRST,
  },
};
