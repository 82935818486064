import React from 'react';
import axios from 'axios';

import { catchError } from 'shared/helpers/catchError';
import { BASE_URL } from 'shared/api/constants';

const userPopoverContent = () => {
  const logout = () => {
    const fetchURL = `${BASE_URL}/auth/logout`;

    axios({
      method: 'get',
      url: `${fetchURL}`,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).catch(catchError);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={logout}
      style={{ cursor: 'pointer' }}
      onClick={logout}
    >
      Logout
    </div>
  );
};

export default userPopoverContent;
