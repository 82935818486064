import { types as t, onSnapshot } from 'mobx-state-tree';

import { SmsResultsModel } from './models/sms/smsResults';
import { SmsDataModel } from './models/sms/smsData';
import { HlrDataModel } from './models/hlr/hlrData';
import { HlrResultsModel } from './models/hlr/hlrResults';
import { initialHlrStore } from './models/hlr/initialHlrStore';
import { initialSmsStore } from './models/sms/initialSmsStore';

const initialState = {
  sms: initialSmsStore,
  hlr: initialHlrStore,
};

const RootModel = t.model('RootModel', {
  sms: t.model({
    data: SmsDataModel,
    results: SmsResultsModel,
  }),
  hlr: t.model({
    data: HlrDataModel,
    results: HlrResultsModel,
  }),
});

export const rootStore = RootModel.create(initialState);

onSnapshot(rootStore, snapshot => {
  localStorage.setItem('tests-store', JSON.stringify(snapshot));
});
