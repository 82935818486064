import React, { Suspense, FC } from 'react';

import Navbar from 'shared/components/Navbar';
import Sidebar from 'shared/components/Sidebar';
import { Inner, Wrapper, ContentContainer } from './style';
import CustomLoader from '../CustomLoader';
import { IPage } from './types';

const LayoutPage: FC<IPage> = ({ children }) => {
  return (
    <Wrapper>
      <Suspense fallback={<CustomLoader />}>
        <Navbar />
        <Inner>
          <Sidebar />
          <ContentContainer>{children}</ContentContainer>
        </Inner>
      </Suspense>
    </Wrapper>
  );
};

export default LayoutPage;
