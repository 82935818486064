import { types as t, flow, destroy, cast } from 'mobx-state-tree';

import smsApi from 'shared/api/sms';
import { ICreateListParams, TCreateParams } from 'shared/api/countries/types';
import {
  createCountriesList,
  getCountriesList,
  getCountriesLists,
  removeCountriesList,
} from 'shared/api/countries';
import { isArray } from 'lodash';
import { fetchGtIds } from 'shared/api/shared';
import { IGetGtIds } from 'shared/api/shared/types';
import { SmsResultType } from '../Hooks';

export const SmsResult = t.model('SmsResult', {
  date: t.number,
  id: t.number,
});

export const CountriesLists = t.model('CountriesLists', {
  label: t.string,
  value: t.number,
});

export const SmsSMPPDataModel = t.model('SmsSMPPDataModel', {
  items: t.union(t.map(t.string), t.array(t.string)),
});

const SmsSMPPModel = t.model('SmsSMPPModel', {
  SMPPData: SmsSMPPDataModel,
});

const SmsSingleTestResultsModel = t.model('SmsSingleTestResultsModel', {
  smsSingleTestResults: t.optional(t.array(SmsResult), []),
});

const SmsCountriesListsModel = t.model('SmsCountriesListsModel', {
  smsCountriesLists: t.optional(t.array(CountriesLists), []),
});

export const SmsDataModel = t
  .compose(SmsSMPPModel, SmsSingleTestResultsModel, SmsCountriesListsModel)
  .actions(self => ({
    fetchSMPPData: flow(function* fetchSMPPData(params: IGetGtIds) {
      const items = yield fetchGtIds(params);
      self.SMPPData.items = items;
    }),
    submitMassTestSMS: flow(function* submitMassTestSMS(body) {
      const { items } = yield smsApi.sendMassTestSMS(body);
      return items;
    }),
    submitSingleTestSMS: flow(function* submitSingleTestSMS(body) {
      const { items } = yield smsApi.sendSingleTestSMS(body);
      return items;
    }),
    getGTSenders: flow(function* getGTSenders() {
      const { data } = yield smsApi.getGTSenders();
      return data;
    }),
    addResults(newResults: SmsResultType) {
      self.smsSingleTestResults.push(newResults);
    },
    removeResult(resultToRemove: number) {
      const result = self.smsSingleTestResults.find(item => item.id === resultToRemove);
      destroy(result as SmsResultType);
    },
    clearAllResults() {
      self.smsSingleTestResults = cast([]);
    },
    createCountriesList: flow(function* (params: TCreateParams) {
      return yield createCountriesList({ id: 0, ...params }, 'sms');
    }),
    updateCountriesList: flow(function* (params: ICreateListParams) {
      return yield createCountriesList(params, 'sms');
    }),
    fetchCountriesLists: flow(function* () {
      const { data } = yield getCountriesLists('sms');
      self.smsCountriesLists = data.items;
    }),
    fetchCountriesList: flow(function* (id: number | number[]) {
      const requestId = isArray(id) ? id : [id];
      return yield getCountriesList(requestId);
    }),
    removeCountriesList: flow(function* (id: number) {
      return yield removeCountriesList(id);
    }),
  }));
