import { Suspense, FC } from 'react';
import { Redirect, BrowserRouter, Switch, Route } from 'react-router-dom';

import CustomLoader from 'shared/components/CustomLoader';
import { routes } from './routes';
import { IRouterProps } from './types';
import NotFound from '../views/NotFound';

const Router: FC<IRouterProps> = ({ LayoutPage }) => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(
          ({ path, exact = false, component: Component, defaultModule, ...rest }) => (
            <Route exact={exact} key={path} path={path}>
              <LayoutPage>
                <Suspense fallback={<CustomLoader />}>
                  <Component path={path} defaultModule={defaultModule} {...rest} />
                </Suspense>
              </LayoutPage>
            </Route>
          ),
        )}
        <Redirect path="/" to="/checking/result/sms" exact />
        <Route>
          <NotFound text="Such a page does not exist" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
