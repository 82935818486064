import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import NavigationTabs from 'shared/components/NavigationTabs';
import { TViewProps, TRenderProps } from './types';
import { tabs } from './tabs';

const TestingView: FC<TViewProps> = ({ modules }) => {
  return (
    <Switch>
      {modules.map(({ exact = false, path, component: Component, ...rest }) => (
        <Route
          key={path}
          path={`${path}`}
          exact={exact}
          render={({
            match: {
              params: { channel = 'sms' },
            },
          }: TRenderProps) => {
            return (
              <>
                <NavigationTabs items={tabs[channel]} />
                <Component />
              </>
            );
          }}
          {...rest}
        />
      ))}
    </Switch>
  );
};

export default TestingView;
