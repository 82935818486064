import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
`;

type TTabProps = {
  isActive?: boolean;
};
export const Tab = styled.div<TTabProps>`
  color: ${({ isActive }) => (isActive ? '#1890ff' : '#696969')};
  border-bottom: ${({ isActive }) =>
    isActive ? '1px solid #1890ff' : '1px solid white'};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 25px;
  //transition-property: color, border-bottom;
  //transition-duration: 2s;
  //transition-timing-function: ease-out;
`;

export const Title = styled.div`
  margin-left: 5px;
`;
